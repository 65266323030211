body,
html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  user-select: none;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-x: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

@font-face {
  font-family: 'gotham';
  src: local('gotham'), url('./fonts/Gotham-Book.woff') format('woff');
}

@font-face {
  font-family: 'gothamBold';
  src: local('gothamBold'), url('./fonts/Gotham-Bold.woff') format('woff');
  font-weight: 800;
}

@font-face {
  font-family: 'cocon';
  src: local('cocon'), url('./fonts/Cocon-Regular.woff') format('woff');
}

@font-face {
  font-family: 'knockout';
  src: local('knockout'),
    url('./fonts/Knockout-HTF47-Bantamweight.ttf') format('truetype');
}

@font-face {
  font-family: 'BrandonGrotesque-Regular';
  src: local('BrandonGrotesque-Regular'),
    url('./fonts/BrandonGrotesque-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'BrandonGrotesque-Bold';
  src: local('BrandonGrotesque-Bold'),
    url('./fonts/BrandonGrotesque-Bold.ttf') format('truetype');
}
